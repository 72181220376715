@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  margin: 0;
  padding: 0;
  border: 0;
  height: 99vh;
  width: 99vw;
  font-family: "Source Sans Pro", sans-serif;
}

.apexcharts-menu {
  background-color: #d0d1d5 !important;
  color: #141414 !important;
}

#footer {
  align-self: flex-end;
  bottom: 0;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}